import React from 'react';

import YoutubeEmbed from '../youtube-embed';

const VIDEO_ID = 'T22zQb7aBjw';

const Demo = () => (
  <section className="py-10 md:py-16 lg:py-20">
    <div className="container grid grid-cols-12">
      <div className="col-span-full text-center md:col-start-2 md:col-end-12 lg:col-start-3 lg:col-end-11">
        <h2 className="text-center text-2xl font-bold tracking-tight sm:text-3xl md:text-4xl md:leading-sm">
          We help to get your notes faster
        </h2>
        <p className="mt-4 text-sm uppercase text-gray-500 md:mt-6">See how it works</p>
        <YoutubeEmbed className="mt-5 md:mt-8" embedId={VIDEO_ID} />
      </div>
    </div>
  </section>
);

export default Demo;

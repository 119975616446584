import { XMarkIcon } from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('*');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(25, 25, 40, 0.5)',
  },
};

const InstructionModal = ({ modalIsOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      htmlOpenClassName="overflow-hidden"
      className="relative top-1/2 left-1/2 max-h-[85%] w-[95%] max-w-[900px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white p-0"
      onRequestClose={closeModal}
    >
      <div className="flex max-w-[900px] flex-col p-8">
        <h3 className="text-3xl font-bold">How to install</h3>
        <p className="mt-4 text-lg">
          After downloading, you may see the Microsoft Defender SmartScreen blocks the setup, just
          click <span className="font-semibold">More info</span> then{' '}
          <span className="font-semibold">Run anyway</span> to continue the installing process.
        </p>
        <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <div className="flex-1">
            <StaticImage
              className="border border-gray-200"
              src="./images/screen-1.png"
              width={500}
              height={468}
              alt="Screen 1"
            />
          </div>
          <div className="flex-1">
            <StaticImage
              className="border border-gray-200"
              src="./images/screen-2.png"
              width={500}
              height={468}
              alt="Screen 2"
            />
          </div>
        </div>
      </div>
      <button className="absolute top-3 right-3 p-2" type="button" onClick={closeModal}>
        <XMarkIcon className="h-6 w-6" />
      </button>
    </ReactModal>
  );
};

InstructionModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default InstructionModal;

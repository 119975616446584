import React from 'react';

import CTA from '../components/cta';
import Demo from '../components/demo';
import Features from '../components/features';
import Hero from '../components/hero';
import HowToUse from '../components/how-to-use';
import Layout from '../components/layout';
import OtherFeatures from '../components/other-features';
import SEO from '../components/seo';

const Home = () => (
  <Layout>
    <Hero />
    <Demo />
    <HowToUse />
    <OtherFeatures />
    <Features />
    <CTA />
  </Layout>
);

export default Home;

export const Head = () => <SEO />;

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Logo from '../../images/logo.inline.svg';
import { APP_NAME } from '../../utils/const';
import DownloadButtons from '../download-buttons';
import { HOW_TO_USE } from '../how-to-use/how-to-use';

const howToUseAnchor = `/#${HOW_TO_USE}`;

const Hero = () => (
  <section
    className="bg-gray-100 sm:py-20 md:py-32 lg:relative lg:flex lg:items-center lg:py-40"
    id="get-started"
  >
    <div className="container grid py-10 lg:grid-cols-2 lg:gap-24 lg:py-0">
      <div>
        <div className="flex items-center space-x-2">
          <Logo className="h-12 w-auto" />
          <span className="mt-2 text-xl font-bold leading-none sm:text-2xl sm:leading-none md:text-3xl md:leading-none lg:text-4xl lg:leading-none">
            {APP_NAME}
          </span>
        </div>
        <div className="mt-10 sm:max-w-xl">
          <h1 className="flat-breaks lg:flat-none text-3xl font-bold leading-tight tracking-tight sm:text-4xl sm:leading-tight md:text-5xl md:leading-tight">
            The fastest way to
            <br /> <span className="highlight-b2g">jot down</span> a note, <br />
            get and <span className="highlight-g2b">paste</span> it anywhere
          </h1>
          <div className="lg:max-w-lg">
            <p className="mt-6 text-lg md:text-2xl">
              Keyboard-oriented desktop application for quick notes and seamless paste
            </p>
            <p className="mt-6 text-lg text-gray-500 md:text-xl">
              Use {APP_NAME} for your, message templates, greetings, signatures, todos, queries and any often-used text
            </p>
            <p className="mt-4 text-lg text-gray-500 md:text-xl">
              Just two{' '}
              <AnchorLink to={howToUseAnchor} className="border-b border-dashed border-gray-400">
                keyboard shortcuts
              </AnchorLink>{' '}
              to remember
            </p>
          </div>
        </div>
        <DownloadButtons className="mt-8 md:mt-10 lg:mt-12" />
      </div>

      <div className="relative mt-10 hidden flex-col lg:mt-0 lg:flex">
        <div className="right-0 top-1/2 mt-10 rounded-xl shadow-2xl md:mt-16 lg:absolute lg:mt-0 lg:w-[500px] lg:min-w-[500px] lg:-translate-y-1/2 xl:w-[600px]">
          <StaticImage
            imgClassName="rounded-lg border border-gray-200"
            width={1440}
            height={1040}
            src="../../images/screen-2.png"
            alt="Screen 2"
          />
        </div>
        <div className="-left-10 top-[calc(50%+4.5rem)] rounded-xl shadow-2xl lg:absolute lg:w-[500px] xl:-left-20">
          <StaticImage
            imgClassName="rounded-xl border border-gray-200"
            src="../../images/screen-1.png"
            alt="Screen 1"
          />
        </div>
      </div>
    </div>
  </section>
);
export default Hero;

import React from 'react';

import DownloadButtons from '../download-buttons';

const CTA = () => (
  <section className="bg-gradient-to-b from-gray-100 py-12 md:py-16">
    <div className="container">
      <div className="flex flex-col justify-between space-y-6 md:flex-row md:space-y-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-5xl md:leading-sm">
          Try it now!
        </h2>
        <DownloadButtons />
      </div>
    </div>
  </section>
);

export default CTA;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const sizeClassNames = {
  sm: 'px-2.5 min-w-[36px] shadow-md border border-gray-200',
  md: 'px-3 min-w-[40px] shadow-lg border-2 border-gray-300',
};

const Key = ({ children, size }) => (
  <span
    className={classNames(
      'inline-flex items-center justify-center py-1.5 font-semibold rounded-md text-gray-600 bg-gray-50',
      sizeClassNames[size]
    )}
  >
    {children}
  </span>
);

Key.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
};

Key.defaultProps = {
  size: 'md',
};

export default Key;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AppleLogo from '../../images/apple.inline.svg';
import WindowsLogo from '../../images/windows.inline.svg';
import InstructionModal from '../instruction-modal';

const buttonGroup = [
  {
    title: 'Windows',
    url: 'https://jnp-update-server.vercel.app/download/win32',
    logo: WindowsLogo,
  },
  {
    title: 'Mac OS',
    url: 'https://jnp-update-server.vercel.app/download/darwin',
    logo: AppleLogo,
  },
];

const DownloadButtons = ({ className }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [showModalLink, setShowModalLink] = useState(false);
  const clickHandler = (buttonTitle) => {
    if (buttonTitle === 'Windows') {
      setTimeout(() => {
        setIsModalOpened(true);
        setShowModalLink(true);
      }, 1000);
    }
    if (typeof window.plausible !== `undefined`) {
      window.plausible('Click', { props: { buttonTitle } });
    }
  };
  return (
    <div className={classNames('relative', className)}>
      <div className="flex">
        {buttonGroup.map(({ title, url, logo: Logo }, index) => (
          <a
            className="transition-bgc mr-2 flex flex-1 items-center rounded-lg bg-black px-4 py-3 duration-200 last:mr-0 hover:bg-gray-700 xs:mr-4 xs:px-5 sm:flex-none"
            href={url}
            key={index}
            onClick={() => clickHandler(title)}
          >
            <Logo className="mr-2 h-7 w-7 xs:mr-3 xs:h-8 xs:w-8 md:mr-5 md:h-9 md:w-9" />
            <span className="flex flex-col items-start text-white">
              <span className="block text-2xs md:text-xs">Download for</span>
              <span className="text-md block font-semibold leading-6 xs:text-lg sm:text-xl sm:leading-7">
                {title}
              </span>
            </span>
          </a>
        ))}
      </div>
      {showModalLink && (
        <button
          className="absolute -bottom-10 mx-auto text-center text-gray-500 underline decoration-gray-500 decoration-dashed underline-offset-4 transition-all duration-200 hover:decoration-transparent"
          type="button"
          onClick={() => setIsModalOpened(true)}
        >
          How to install on Windows?
        </button>
      )}
      <InstructionModal modalIsOpen={isModalOpened} setIsOpen={setIsModalOpened} />
    </div>
  );
};

DownloadButtons.propTypes = {
  className: PropTypes.string,
};

DownloadButtons.defaultProps = {
  className: null,
};

export default DownloadButtons;

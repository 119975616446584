import {
  ChevronDoubleDownIcon,
  ClipboardDocumentListIcon,
  PencilIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import UAParser from 'ua-parser-js';

import arrow from '../../images/arrow.png';
import { APP_NAME } from '../../utils/const';
import Key from '../key';

const parser = new UAParser();
const cmdKey = parser.getOS()?.name?.toLowerCase() === 'mac os' ? 'Cmd' : 'Ctrl';
export const HOW_TO_USE = 'how-to-use';

const HowToUse = () => (
  <section className="bg-gray-100 pb-10">
    <div className="container py-10 md:py-16 lg:py-20">
      <h2
        className="text-center text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl md:leading-sm"
        id={HOW_TO_USE}
      >
        How to use
      </h2>
      <div className="mt-8 space-y-10 md:mt-12 md:space-y-16 lg:mt-16 lg:space-y-20">
        <div className="grid grid-cols-12 items-center gap-y-8 md:gap-y-10 lg:gap-x-8">
          <div className="col-span-full flex space-x-5 lg:col-span-6">
            <span className="flex h-12 w-12 shrink-0 items-center justify-center rounded-xl bg-white shadow-md">
              <PencilIcon className="h-6 w-6" />
            </span>
            <div>
              <h3 className="text-2xl font-semibold leading-tight md:text-3xl md:leading-tight lg:text-4xl lg:leading-tight">
                Add your text notes from anywhere
              </h3>
              <div className="mt-4 text-lg text-gray-500 md:text-xl">
                <div>
                  Use <Key>{cmdKey}</Key> + <Key>Shift</Key> + <Key>Enter</Key> to start adding a
                  note.
                </div>
                <div className="mt-3">No matter what app is open right now.</div>
              </div>
            </div>
          </div>

          <div className="col-span-full rounded-xl shadow-2xl lg:col-start-8 lg:col-end-13">
            <StaticImage
              imgClassName="rounded-xl border border-gray-200"
              src="../../images/screen-1.png"
              alt="Create notes"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 items-center md:gap-x-8">
          <div className="order-1 col-span-full mt-10 rounded-xl shadow-2xl lg:order-none lg:col-span-5 lg:mt-0">
            <StaticImage
              imgClassName="rounded-xl border border-gray-200"
              src="../../images/screen-2.png"
              alt="Navigate notes list"
            />
          </div>

          <div className="col-span-full flex flex-col space-y-10 md:space-y-12 lg:col-start-7 lg:col-end-13 lg:space-y-16">
            <div className="flex space-x-5">
              <span className="flex h-12 w-12 shrink-0 items-center justify-center rounded-xl bg-white shadow-md">
                <TagIcon className="h-6 w-6" />
              </span>
              <div>
                <h3 className="text-2xl font-semibold leading-tight md:text-3xl md:leading-tight lg:text-4xl lg:leading-tight">
                  Use #tags to sort them
                </h3>
                <p className="mt-4 text-lg text-gray-500 md:text-xl">
                  We recommend to use #pin for your most used items.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <span className="flex h-12 w-12 shrink-0 items-center justify-center rounded-xl bg-white shadow-md">
                <ClipboardDocumentListIcon className="h-6 w-6" />
              </span>
              <div>
                <h3 className="text-2xl font-semibold leading-tight md:text-3xl md:leading-tight lg:text-4xl lg:leading-tight ">
                  Use <Key>{cmdKey}</Key> + <Key>Shift</Key> + <Key>L</Key> in any app to see the
                  list of your notes
                </h3>
                <p className="mt-4 space-y-1.5 text-lg text-gray-500 md:text-xl">
                  Navigate them with <Key size="sm">&darr;</Key> <Key size="sm">&uarr;</Key> arrows.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center gap-y-8 pb-28 sm:pb-40 md:gap-y-10 md:pb-36 lg:gap-x-8 lg:pb-20">
          <div className="col-span-full flex space-x-5 lg:col-span-6">
            <span className="flex h-12 w-12 shrink-0 items-center justify-center rounded-xl bg-white shadow-md">
              <ChevronDoubleDownIcon className="h-6 w-6" />
            </span>
            <div>
              <h3 className="text-2xl font-semibold leading-tight md:text-3xl md:leading-tight lg:text-4xl lg:leading-tight">
                Hit <Key>Enter</Key> and {APP_NAME} will copy and paste the text for you.
              </h3>
              <div className="mt-4 text-lg text-gray-500 md:text-xl">
                <div>
                  Or <Key size="sm">{cmdKey}</Key> + <Key size="sm">Enter</Key> if you just want to
                  copy it.
                </div>
              </div>
            </div>
          </div>
          <div className="relative col-span-full lg:col-start-8 lg:col-end-13 lg:-ml-16 xl:ml-0">
            <div className="rounded-xl shadow-2xl">
              <StaticImage
                imgClassName="rounded-xl border border-gray-200"
                src="../../images/signature.png"
                width={1024}
                height={921}
                alt="Paste signature example"
              />
            </div>
            <div className="absolute top-[28%] left-1/2 w-4/5 -translate-x-1/2 rounded-xl shadow-2xl lg:-right-10 lg:left-auto lg:w-[75%] lg:translate-x-0 xl:-right-36 xl:w-4/5 xl:translate-x-[calc(-50%+8rem)]">
              <div className="relative">
                <StaticImage
                  imgClassName="rounded-xl border border-gray-200"
                  src="../../images/paste-result.png"
                  alt="Paste result"
                />
                <img
                  className="absolute top-4 -left-16 hidden w-20 rotate-[-60deg] md:top-8 md:-left-28 md:w-36 lg:-left-16 lg:-top-3.5 lg:block lg:w-24 lg:rotate-[-50deg] xl:-top-0.5 xl:-left-20 xl:w-28 xl:rotate-[-55deg]"
                  src={arrow}
                  alt=""
                  aria-hidden
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HowToUse;

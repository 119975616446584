import {
  ClockIcon,
  ClipboardDocumentCheckIcon,
  PlusSmallIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

import CheckIcon from '../../images/check.inline.svg';

const features = [
  {
    icon: ClipboardDocumentCheckIcon,
    title: 'Implemented features',
    items: [
      'Keyboard navigation',
      'Quick paste',
      'Pinned items & tags',
      'Emoji companion per note',
      'Cloud synchronization',
      'Global search',
    ],
  },
  {
    icon: ClockIcon,
    title: 'Upcoming features',
    items: [
      'Notes with reminders',
      'Markdown support',
      'Smart to-do lists',
      'Paste templates',
      'Share lists & collaborations',
      '...your idea?',
    ],
  },
];

const Features = () => (
  <section className="bg-slate-50 md:bg-gray-100 lg:py-32">
    <div className="flex flex-col bg-slate-200 sm:px-6 sm:pb-16 md:rounded-[80px] md:px-8  md:pt-16 lg:container lg:p-20">
      <div className="grid md:grid-cols-2">
        {features.map(({ title, icon: Icon, items }, index) => {
          const isEven = index % 2 === 0;
          return (
            <div
              className={classNames(
                'rounded px-4 py-10 sm:rounded-[80px] sm:px-10 md:py-12 lg:px-12',
                isEven && 'bg-gradient-to-r from-[#1d4ed8] to-[#10b981] text-white'
              )}
              key={index}
            >
              <Icon className="h-12 w-12" />
              <h3 className="mt-6 pb-2 text-2xl font-bold md:text-3xl lg:text-4xl">{title}</h3>
              <ul
                className={classNames(
                  'mt-4 divide-y text-lg',
                  isEven ? 'divide-white' : 'divide-gray-300'
                )}
              >
                {items.map((item, idx) => (
                  <li
                    className={classNames({ 'text-gray-100': isEven }, 'flex items-center py-2')}
                    key={idx}
                  >
                    {isEven ? (
                      <CheckIcon className={classNames('h-8 w-8 flex-none fill-white')} />
                    ) : (
                      <PlusSmallIcon
                        className={classNames('h-8 w-8 flex-none fill-gray-400 px-2')}
                      />
                    )}
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
      <a
        className="mx-auto mt-0 pb-16 text-center text-gray-500 underline decoration-gray-500 decoration-dashed underline-offset-4 transition-all duration-200 hover:decoration-transparent md:mt-10 lg:pb-0"
        href="https://jotnpaste.canny.io/feedback"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Share your ideas</span>
        <LinkIcon className="ml-1.5 inline-block h-4 w-4" />
      </a>
    </div>
  </section>
);

export default Features;

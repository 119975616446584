import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { APP_NAME } from '../../utils/const';

const features = [
  {
    title: 'Clipboard history',
    description:
      'The application keeps a history of the last 20 copied text elements so you can easily reuse copied text.',
    imageName: 'clipboard',
  },
  {
    title: 'Multiple paste',

    description: 'Select multiple notes with Shirt+Arrows and paste all of them by clicking Enter',
    imageName: 'multiple-paste',
  },
  {
    title: 'Cloud synchronization',
    description: `Your text notes are always up-to-date between computers if you're using the same account.`,
    imageName: 'cloud-sync',
  },
  {
    title: 'Email & Link extractor',
    description: `${APP_NAME} parses the content and shows links and emails in preview mode. Just press 'Space' to enter the preview.`,
    imageName: 'parser',
  },
  {
    title: 'Data export',
    description: `Export your notes to Google Sheets, JSON, CSV or plain text formats`,
    imageName: 'exportData',
  },
];

const animationProps = {
  initial: {
    opacity: 0,
    translateY: 20,
  },
  animate: {
    opacity: 1,
    translateY: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
  exit: {
    opacity: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.2 },
  },
};

const OtherFeatures = () => {
  const { clipboard, multiplePaste, cloudSync, parser, exportData } = useStaticQuery(graphql`
    query {
      clipboard: file(relativePath: { eq: "clipboard.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1000, quality: 100)
        }
      }
      multiplePaste: file(relativePath: { eq: "multiple-paste.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1000, quality: 100)
        }
      }
      cloudSync: file(relativePath: { eq: "screen-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1000, quality: 100)
        }
      }
      parser: file(relativePath: { eq: "parser.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1000, quality: 100)
        }
      }
      exportData: file(relativePath: { eq: "export-data.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1000, quality: 100)
          }
      }
    }
  `);
  const [activeItem, setActiveItem] = useState(0);
  const intervalRef = useRef(null);

  const handleClickButton = (index) => {
    setActiveItem(index);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveItem((item) => (item + 1 > features.length - 1 ? 0 : item + 1));
    }, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const images = {
    clipboard,
    'multiple-paste': multiplePaste,
    'cloud-sync': cloudSync,
    parser,
    exportData,
  };

  return (
    <section className="py-20 lg:py-32">
      <div className="container">
        <h2 className="text-center text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl md:leading-sm">
          More productivity features
        </h2>
        <div className="mt-8 grid grid-cols-12 lg:mt-12">
          <div className="col-span-full grid grid-cols-2 items-center justify-center gap-x-3 gap-y-4 border-t border-gray-200 py-5 md:flex md:flex-row lg:col-span-5 lg:flex-col lg:gap-y-0 lg:space-x-0 lg:border-t-0">
            {features.map(({ title, description }, index) => (
              <button
                className={classNames(
                  'group relative flex flex-col items-center justify-center rounded-full py-2 px-4 text-center transition-[background] duration-200 hover:bg-gradient-to-r hover:from-green-100 hover:to-green-50 lg:items-start lg:rounded-r-none lg:rounded-l-xl lg:p-6',
                  {
                    'bg-gradient-to-r from-green-100 to-green-50': activeItem === index,
                  }
                )}
                type="button"
                key={title}
                onClick={() => handleClickButton(index)}
              >
                <h3 className="text-sm font-semibold leading-tight sm:text-sm lg:text-lg lg:leading-none [&:not(:focus-visible)]:focus:outline-none">
                  {title}
                </h3>
                <p className="mt-3 hidden text-left lg:block">{description}</p>
              </button>
            ))}
          </div>
          <div className="relative col-span-full lg:col-span-7">
            <AnimatePresence initial={false} exitBeforeEnter>
              {features.map(({ title, description, imageName }, index) =>
                index === activeItem ? (
                  <Fragment key={title}>
                    <motion.p
                      className="relative mx-auto max-w-2xl text-center text-base lg:hidden"
                      {...animationProps}
                    >
                      {description}
                    </motion.p>
                    <motion.div
                      className="-top-5 left-0 w-full overflow-hidden lg:absolute lg:w-[45rem] drop-shadow-2xl"
                      {...animationProps}
                    >
                      <GatsbyImage
                        image={getImage(images[imageName])}
                        alt={title}
                        className="mt-9 h-full w-full object-cover shadow-xl shadow-blue-900/20"

                      />
                    </motion.div>
                  </Fragment>
                ) : null
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherFeatures;
